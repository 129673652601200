import React, { FC, ReactElement } from 'react';

import { trans } from '../../../../helpers/trans';
import { KvkSearchResult } from '../../../../models/KvkSearchResult';

import './CompanyKVKRadioInput.scss';

type InputProps = JSX.IntrinsicElements['input'];

interface CompanyKVKRadioInputProps extends InputProps {
    kvkSearchResult: KvkSearchResult;
    className?: string;
}

const CompanyKVKRadioInput: FC<CompanyKVKRadioInputProps> = ({
    kvkSearchResult,
    className = '',
    ...inputProps
}): ReactElement => (
    <div className={`company-kvk-radio-input ${className}`}>
        <input
            {...inputProps}
            id={kvkSearchResult.uuid}
            value={kvkSearchResult.uuid}
            className="company-kvk-radio-input__input"
            type="radio"
        />

        <label
            htmlFor={kvkSearchResult.uuid}
            className="company-kvk-radio-input__label"
        >
            <div className="company-kvk-radio-input__title">
                <span className="company-kvk-radio-input__business-name">
                    {kvkSearchResult.companyName}
                </span>

                <span>-</span>

                {kvkSearchResult.isMainBranch && (
                    <>
                        <span className="company-kvk-radio-input__is-main-branch">
                            {trans('forms.kvkRadioInput.mainBranch')}
                        </span>
                        <span>-</span>
                    </>
                )}

                <span className="company-kvk-radio-input__city">
                    {kvkSearchResult.city}
                </span>
            </div>

            <div className="company-kvk-radio-input__properties">
                <span className="company-kvk-radio-input__property">
                    {trans('forms.kvkRadioInput.kvkNumber', {
                        kvkNumber: kvkSearchResult.kvkNumber,
                    })}
                </span>

                <span>-</span>

                {kvkSearchResult.branchNumber && (
                    <>
                        <span className="company-kvk-radio-input__property">
                            {trans('forms.kvkRadioInput.branchNumber', {
                                branchNumber: kvkSearchResult.branchNumber,
                            })}
                        </span>
                        <span>-</span>
                    </>
                )}

                {kvkSearchResult.streetName && (
                    <span className="company-kvk-radio-input__property">
                        {`${kvkSearchResult.streetName},`}
                    </span>
                )}

                <span className="company-kvk-radio-input__property">
                    {kvkSearchResult.city}
                </span>
            </div>
        </label>
    </div>
);

export default CompanyKVKRadioInput;
